import React from 'react'
import pravidla from "../images/pravidla.jpg"

function Pravidla() {

    const rules = [
        "Řiďte se pokyny pracovníků borůvkové farmy.",
        "Zákaz tašek a batohů. S sebou pouze nezbytné osobní věci (např. v ledvince) a pití do ruky.",
        "Borůvky sbíráte do vlastních či zakoupených nádob s minimálním odběrem 2kg / os.",
        "Veškeré vlastní nádoby/krabičky jste povinni ukázat obsluze a nechat zvážit před vstupem i po opuštění farmy.",
        "Pouze obsluha samosběru určuje místo pro trhání borůvek.",
        "Sbíráte všechny zralé plody bez ohledu na velikost.",
        "Nepoškozujete porost borůvek, nepřeskakujete keře a nevstupujete do řádků, které Vám nebyly přiděleny pro samosběr.",
        "Vstup dětem mladší 12 let a psům není povolen.",
        "Maminky s batolaty vstup povolen pouze s terénním kočárkem nebo nosítkem/šátkem.",
        "V celém areálu borůvkové farmy a na všech polích platí zákaz kouření.",
        "Po ukončení trhání si necháte zvážit všechny nádoby s borůvkami.",
        "Platba pouze v hotovosti."
    ]


  return (
    <div className="bg-slate-100">
    <div id="pravidla" className="container mx-auto px-5 py-16">
      <h3 className="font-hand text-4xl lg:text-6xl">Pravidla <span className="text-indigo-600">samosběru</span></h3>
    <div className="lg:flex justify-between items-center lg:pt-10">
        <div>
        <ul className="flex flex-col gap-2 py-5">
        {rules.map((rule, index) => (
            <li key={index} className="text-gray-500">• {rule}</li>
        ))}
            
        </ul>

        <p className="font-semibold">Děkujeme vřele za pochopení. Moc se na Vaši návštěvu těšíme. Borůvkám zdar!</p>
        </div>
        <img src={pravidla} alt="Fotka z léta" className="hidden lg:block w-1/3 shadow-xl shadow-black/30" />

    </div>
    </div>
    </div>
  )
}

export default Pravidla